import { Add, Delete, Edit, FileUpload, Save } from '@mui/icons-material'
import { Alert, Box, Button, CircularProgress, Dialog, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import MuiLink from '@mui/material/Link'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import NumberFormatCustom from '../utils/NumberFormatCustom'

const requestRepository = [ 'github.com', 'gitlab.com', 'launchpad.net' ]
const packageProgrammingLanguages = [ 'Node.js'/*, 'Java', 'Python'*/ ]

const Projects = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    
    const query = useQuery()
    const paymentStatus = query.get('payment')

    let history = useHistory()

    const [projects, setProjects] = useState([])
    const [subscription, setSubscription] = useState()
    const [importPackageOpen, setImportPackageOpen] = useState(false)
    const [addProjOpen, setAddProjOpen] = useState(false)
    const [updateProjOpen, setUpdateProjOpen] = useState(false)
    
    const [repository, setRepository] = useState('')
    const [path, setPath] = useState('')
    const [preferenceLvl, setPreferenceLvl] = useState('')

    const [openWarning, setOpenWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState(null)
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [openInfo, setOpenInfo] = useState(false)
    const [infoMessage, setInfoMessage] = useState(null)

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [programmingLanguage, setProgrammingLanguage] = useState('')
    const [acceptExtensions, setAcceptExtensions] = useState('')

    const [isLoadingModal, setIsLoadingModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const submitSaveProject = (e) => {
        e.preventDefault()

        setIsLoadingModal(true)

        const project = {
            repoUrl: repository,
            projectPath: path,
            preferenceLvl: preferenceLvl
        }
        axios.post(`/projects`, project)
        .then(() => {
            setAddProjOpen(false)
            fetchProjects()
            clearFormFields()
            setOpenInfo(true)
            setInfoMessage('Project added successfully.')
            setIsLoadingModal(false)
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoadingModal(false)
        })
    }

    const submitUpdateProject = (e) => {
        e.preventDefault()

        setIsLoadingModal(true)

        const project = {
            repoUrl: repository,
            projectPath: path,
            preferenceLvl: preferenceLvl
        }
        axios.put(`/projects`, project)
        .then(() => {
            setUpdateProjOpen(false)
            fetchProjects()
            clearFormFields()
            setOpenInfo(true)
            setInfoMessage('Preference level updated successfully.')
            setIsLoadingModal(false)
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoadingModal(false)
        })
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
        setSelectedFileName(event.target.files[0].name)
    }

    const submitImportPackage = (e) => {
        e.preventDefault()

        if(selectedFile === null) {
            setOpenWarning(true)
            setWarningMessage('No file chosen.')
            return
        }
        
        setIsLoadingModal(true)

        const formData = new FormData();
        formData.append("file", selectedFile);

        axios.post(`/projects/import`, formData, 
            { headers: { "Content-Type": "multipart/form-data"} }
        )
        .then(({ data: { message } }) => {
            setImportPackageOpen(false)
            fetchProjects()
            setOpenInfo(true)
            if(message) {
                setInfoMessage(message)
            }else {
                setInfoMessage('Projects imported from package manager successfully.')
            }
            setIsLoadingModal(false)
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error?.response?.data?.message) {
                setOpenWarning(true)
                setWarningMessage(error?.response?.data?.message)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoadingModal(false)
        })
    }

    const onClickRemoveProject = (userProjectId) => {
        const continueApp = window.confirm('Do you really want to remove this project?')

        if (!continueApp) return

        setIsLoading(true)

        axios.delete('/projects/' + userProjectId)
            .then(() => {
                fetchProjects()
                setOpenInfo(true)
                setInfoMessage('Project removed successfully.')
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    const fetchSubscription = () => {
        setIsLoading(true)

        const checkPayment = localStorage.getItem('check-payment')
        axios.get(`/subscriptions/my${checkPayment ? '?forceReloadSubscription=true' : ''}`)
            .then(({ data: { userSubscription, paymentSucceeded } }) => {
                if (paymentSucceeded)
                    history.push('/sponsorship?payment=succeeded')
                setSubscription(userSubscription)
                localStorage.removeItem('check-payment')
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }
    
    const fetchProjects = () => {
        setIsLoading(true)

        axios.get('/projects/my')
            .then(({ data }) => {
                setProjects(data)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    const clearFormFields = () => {
        setRepository('')
        setPath('')
        setPreferenceLvl('')
    }

    const onClickAddProject = () => {
        clearFormFields()
        setAddProjOpen(true)
    }

    const onClickImportPackage = () => {
        setProgrammingLanguage('')
        setAcceptExtensions('')
        setSelectedFile(null)
        setSelectedFileName('')
        setImportPackageOpen(true)
    }

    const onChangeProgrammingLanguage = (programmingLanguage) => {
        setProgrammingLanguage(programmingLanguage)
        if(programmingLanguage === 'Node.js') {
            setAcceptExtensions('.json')
        }else if(programmingLanguage === 'Java') {
            setAcceptExtensions('.xml')
        }else if(programmingLanguage === 'Python') {
            setAcceptExtensions('.doc')
        }
    }

    const onClickUpdateProject = (userProjectId) => {
        axios.get('/projects/' + userProjectId)
            .then(({ data: { userProject } }) => {
                if(userProject && userProject.project) {
                    setRepository(userProject.project.repo)
                    setPath(userProject.project.path)
                    setPreferenceLvl(userProject.preferenceLvl)
                    setUpdateProjOpen(true)
                }  
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
            })
    }

    useEffect(() => {
        fetchProjects()
        fetchSubscription()
    }, [])

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant="caption"><span style={{fontWeight: 'bold'}}>Current Subscription:</span> {subscription ? `${subscription.plan.name} (USD ${subscription.plan.price ? subscription.plan.price.toFixed(2) : subscription.price.toFixed(2)} per month)` : '-'}</Typography>
                <div>
                    <Button variant="outlined" style={{marginRight: 10}} onClick={() => onClickImportPackage()}>Import projects from package manager</Button>
                    <Button variant="outlined" style={{marginRight: 10}} onClick={() => onClickAddProject()}>Add Project</Button>
                    <Button variant="outlined" onClick={() => history.push('/subscribe')}>Add Funds</Button>
                </div>
            </div>
            {!isLoading && (
                <div style={{flexGrow: 1}}>
                    {projects.map(proj => (
                        <div key={proj._id} style={{ borderBottomStyle: 'solid', borderBottomWidth: 1, borderColor: 'lightgray', padding: 10 }}>
                            <Typography variant="h6"><MuiLink target="_blank" href={`https://${proj.project}`}>{proj.project}</MuiLink></Typography>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography title="Set the preference level for funds' allocation" variant="caption" style={{ marginRight: 50}}>
                                    <span style={{fontWeight: 'bold'}}>Preference Lvl: </span>
                                    {proj.preferenceLvl}
                                    <Button startIcon={<Edit />} size="small" style={{marginLeft: 5}} onClick={() => onClickUpdateProject(proj._id)}>Change</Button>
                                </Typography>
                                <Typography title="Number of requests you supported this project" variant="caption" style={{ marginRight: 50}}>
                                    <span style={{fontWeight: 'bold'}}># Supported Requests: </span>
                                    {proj.supportedRequestsCount || 0}
                                </Typography>
                                <Typography title="Total amount of contributions in this project" variant="caption" style={{ marginRight: 50}}>
                                    <span style={{fontWeight: 'bold'}}>Contributed: </span>
                                    USD {proj.contributionAmount}
                                </Typography>
                                <Button startIcon={<Delete />} size="small" style={{marginLeft: 5}} onClick={() => onClickRemoveProject(proj._id)}>Remove</Button>
                            </div>
                        </div>
                    ))}
                </div>
            )} 
            {isLoading && 
                <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                    <div>
                        <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                        <InputLabel>Loading...</InputLabel>
                    </div>
                </Box>
            }             
            <Dialog open={addProjOpen} aria-labelledby="form-dialog-title">
                <form onSubmit={submitSaveProject}>
                    <div style={{width:500, padding: 20}}>
                        <h3>Add Project</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end'}}>
                            <FormControl required style={{width: 150}}>
                                <InputLabel>Repository</InputLabel>
                                <Select value={repository || ''} onChange={(e) => setRepository(e.target.value)} marginright='100'>
                                    {requestRepository.map((c) => (
                                        <MenuItem key={c} value={c}>{c}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <span style={{padding: 5}}>/</span>
                            <TextField
                                required
                                label='Path'
                                value={path}
                                onChange={(e) => setPath(e.target.value)}
                            />
                        </div>
                        <TextField
                            style={{width: 150}}
                            InputProps={{ inputComponent: NumberFormatCustom, }}
                            required
                            label='Preference Level'
                            value={preferenceLvl}
                            onChange={(e) => setPreferenceLvl(e.target.value)}
                        />
                        <div style={{display:'flex', direction: 'row', justifyContent: 'end'}}>
                            <Button style={{marginRight: 10}} onClick={() => setAddProjOpen(false)} variant="outlined">Cancel</Button>
                            <Button type="submit" startIcon={isLoadingModal ? <CircularProgress size={26} color="secondary" /> : <Add />}>Add</Button>
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog open={paymentStatus == 'succeeded'} aria-labelledby="form-dialog-title">
                <div style={{padding: 20}}>
                    <h3>Subscription completed succesfully!</h3>
                    <p>Now you can setup your projects to allocate your resouces the best way possible!</p>
                    <Button onClick={() => history.push('/sponsorship')}>OK</Button>
                </div>
            </Dialog>
            <Dialog open={updateProjOpen} aria-labelledby="form-dialog-title">
                <form onSubmit={submitUpdateProject}>
                    <div style={{width:500, padding: 20}}>
                        <h3>Update Project</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end'}}>
                            <FormControl required style={{width: 150}}>
                                <InputLabel>Repository</InputLabel>
                                <Select disabled value={repository || ''} onChange={(e) => setRepository(e.target.value)} marginright='100'>
                                    {requestRepository.map((c) => (
                                        <MenuItem key={c} value={c}>{c}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <span style={{padding: 5}}>/</span>
                            <TextField
                                disabled
                                required
                                label='Path'
                                value={path}
                                onChange={(e) => setPath(e.target.value)}
                            />
                        </div>
                        <TextField
                            style={{width: 150}}
                            InputProps={{ inputComponent: NumberFormatCustom, }}
                            required
                            label='Preference Level'
                            value={preferenceLvl}
                            onChange={(e) => setPreferenceLvl(e.target.value)}
                        />
                        <div style={{display:'flex', direction: 'row', justifyContent: 'end'}}>
                            <Button style={{marginRight: 10}} onClick={() => setUpdateProjOpen(false)} variant="outlined">Cancel</Button>
                            <Button type="submit" startIcon={isLoadingModal ? <CircularProgress size={26} color="secondary" /> : <Save />}>Save</Button>
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog open={importPackageOpen} aria-labelledby="form-dialog-title">
                <form onSubmit={submitImportPackage}>
                    <div style={{width:500, padding: 20}}>
                        <h3>Import projects from package manager</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end'}}>
                            <FormControl required style={{width: 150}}>
                                <InputLabel>Language</InputLabel>
                                <Select value={programmingLanguage || ''} onChange={(e) => onChangeProgrammingLanguage(e.target.value)}>
                                    {packageProgrammingLanguages.map((c) => (
                                        <MenuItem key={c} value={c}>{c}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'end'}}>
                            <label htmlFor="upload_file" className='MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root  css-ntmati-MuiButtonBase-root-MuiButton-root'>Choose File</label>
                            <input disabled={programmingLanguage === ''} id="upload_file" style={{display: 'none'}} accept={acceptExtensions} type="file" onChange={handleFileSelect} />
                            <label style={{marginBottom: 10, marginLeft: 10}}>{selectedFileName}</label>
                        </div>                        

                        <div style={{display:'flex', direction: 'row', justifyContent: 'end'}}>
                            <Button style={{marginRight: 10}} onClick={() => setImportPackageOpen(false)} variant="outlined">Cancel</Button>
                            <Button type="submit" startIcon={isLoadingModal ? <CircularProgress size={26} color="secondary" /> : <FileUpload />}>Import</Button>
                        </div>
                    </div>
                </form>
            </Dialog>
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {'Error: ' + errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>  
            <Snackbar
                open={openWarning}
                autoHideDuration={6000}
                onClose={() => setOpenWarning(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="warning" sx={{ width: '100%' }} >
                    {warningMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openInfo}
                autoHideDuration={6000}
                onClose={() => setOpenInfo(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="info" sx={{ width: 350 }} >
                    {infoMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Projects