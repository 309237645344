import { Alert, Autocomplete, Button, CircularProgress, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { repoPlatforms } from '../Utils'
import { Save } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

const requestTypes = [ 'Feature', 'Fix' ]

const DevRequestForm = () => {
    let history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    const [platform, setPlatform] = useState('github.com')
    const [path, setPath] = useState('')
    const [type, setType] = useState()
    const [relatedIssues, setRelatedIssues] = useState([])

    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const submit = (e) => {
        e.preventDefault()
        //if (!validate()) return

        setIsLoading(true)

        const devReq = {
            platform,
            path,
            type,
            relatedIssues
        }
        axios.post(`/dev-requests`, devReq)
        .then(({ data: { _id: devReqId } }) => {
            enqueueSnackbar('Request created successfully.', { variant: 'success' })
            setIsLoading(false)
            history.push('/dev-requests/' + devReqId)
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoading(false)
        })
    }

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    return (
        <>
        <form onSubmit={submit}>
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl required>
                    <InputLabel>Type</InputLabel>
                    <Select 
                        required 
                        name="type" 
                        value={type || ''} 
                        onChange={(e) => setType(e.target.value)}>
                            {requestTypes.map((c) => (
                                <MenuItem key={c} value={c}>{c}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                    <FormControl required style={{ width: 180 }}>
                        <InputLabel>Repository</InputLabel>
                        <Select value={platform || ''} onChange={(e) => setPlatform(e.target.value)}>
                            {repoPlatforms.map((c) => (
                                <MenuItem key={c} value={c}>{c}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <span style={{ padding: 5 }}>/</span>
                    <TextField
                        fullWidth
                        required
                        label='Path'
                        value={path}
                        onChange={(e) => setPath(e.target.value)}
                    />
                </div>
                <Autocomplete
                    required
                    multiple
                    freeSolo
                    options={[]}
                    fullWidth
                    value={relatedIssues || []}
                    onChange={(e, newValue) => {
                        setRelatedIssues(newValue.flat())
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={relatedIssues.length === 0 ? 'Related Issues' : 'Related Issues *'}
                            required={relatedIssues.length === 0}
                        />
                    )}
                />
        </DialogContent>
            <DialogActions>
                <Button onClick={() => history.goBack()} variant="outlined">
                    Cancel
                </Button>
                <Button startIcon={isLoading ? <CircularProgress size={26} color="secondary" /> : <Save />} type="submit">
                    Save
                </Button>
            </DialogActions>
        </form>
        <Snackbar
            open={openError}
            autoHideDuration={6000}
            onClose={() => setOpenError(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity="error" sx={{ width: 350 }} >
                {'Error: ' + errorMessage}
            </Alert>
        </Snackbar>
        <Snackbar
            open={openErrorUnauthorized}
            autoHideDuration={6000}
            onClose={() => onCloseSnackbarErrorUnauthorized(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity="error" sx={{ width: 350 }} >
                {errorUnauthorizedMessage}
            </Alert>
        </Snackbar>
        </>
    )
}

export default DevRequestForm;