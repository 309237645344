import { LockOpen } from '@mui/icons-material'
import { Alert, Button, CircularProgress, Snackbar, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { auth, sendEmailVerification, signUp, updateProfile } from './firebase'

const SignUp = () => {
    const history = useHistory()
    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [name, setName] = useState('')
    const [waiting, setWaiting] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)
    const [infoMessage, setInfoMessage] = useState(null)
    const [openWarning, setOpenWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState(null)
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const submit = (event) => {
        event.preventDefault();
        if (password === '') return
        
        if (confirmPassword !== password) {
            setOpenWarning(true)
            setWarningMessage('Password fields must match!')
            return
        }
        setWaiting(true)
    
        signUp(email, password)
          .then(() => {
            updateProfile(auth.currentUser, { displayName: name }).then(() => {
                sendEmailVerification(auth.currentUser).then(() => {
                    setOpenInfo(true)
                    setInfoMessage('A verification email was sent to your email. Check your inbox.')
                    setWaiting(false)
                })
                .catch(error => {
                    catchErrorSignup(error)
                    setWaiting(false)
                })
            })
            .catch(error => {
                catchErrorSignup(error)
                setWaiting(false)
            })
          })
          .catch(error => {
            catchErrorSignup(error)
            setWaiting(false)
        })
    }

    const catchErrorSignup = (error) => {
        let errorMessage = error.message
        switch (error.code.toString()) {
            case 'auth/weak-password':
              errorMessage = 'Password should be at least 6 characters'
              break;
            case 'auth/email-already-in-use':
              errorMessage = 'There is already a user registered with this email.'
              break;
            default:
              break;
          }
        setOpenError(true)
        setErrorMessage(errorMessage)
    }

    const onCloseSnackbarSuccess = () => {
        setOpenInfo(false)
        history.push('/sign-in')
    }
    
    return (
        <>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <form onSubmit={submit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirm password"
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                        
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{marginTop: 50}}
                    startIcon={waiting ? <CircularProgress size={26} color="secondary" /> : <LockOpen />}
                >
                    Sign Up
                </Button>
            </form>
        </div>
        <Snackbar
            open={openWarning}
            autoHideDuration={6000}
            onClose={() => setOpenWarning(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity="warning" sx={{ width: 350 }} >
                {warningMessage}
            </Alert>
        </Snackbar>
        <Snackbar
            open={openInfo}
            autoHideDuration={6000}
            onClose={() => onCloseSnackbarSuccess()}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity="info" sx={{ width: 350 }} >
                {infoMessage}
            </Alert>
        </Snackbar>
        <Snackbar
            open={openError}
            autoHideDuration={6000}
            onClose={() => setOpenError(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity="error" sx={{ width: 350 }} >
                {errorMessage}
            </Alert>
        </Snackbar>
        </>
    )
}

export default SignUp