import { ArrowUpward, Check } from '@mui/icons-material'
import { Alert, Button, CircularProgress, Dialog, InputAdornment, Snackbar, TextField, Typography } from '@mui/material'
import MuiLink from '@mui/material/Link'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from "react-router-dom"
import NumberFormatCustom from '../utils/NumberFormatCustom'

const ApplicationList = ({ applications, type = 'byUser', refetch }) => {
  let history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const [applicationAvailability, setApplicationAvailability] = useState(null)
  const [submitPullRequest, setSubmitPullRequest] = useState(null)
  const [pr, setPr] = useState('')

  const [openError, setOpenError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
  const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)

  const [isLoadingModal, setIsLoadingModal] = useState(false)

  const confirmAvailability = () => {
    setIsLoadingModal(true)

    axios.post(`/applications/${applicationAvailability._id}/confirm-availability`)
    .then(() => {
      setApplicationAvailability(null)
      enqueueSnackbar('Availability confirmed successfully.', { variant: 'success' })
      setIsLoadingModal(false)
      refetch()
    })
    .catch(error => {
        console.log('error', error)
        if (error?.response?.status === 401) {
            setOpenErrorUnauthorized(true)
            if(error?.response?.data?.error) {
                setErrorUnauthorizedMessage(error?.response?.data?.error)
            }else {
                setErrorUnauthorizedMessage('Authentication is required to make this request')
            }
        }else if(error?.response?.data?.error) {
            setOpenError(true)
            setErrorMessage(error?.response?.data?.error)
        }else if(error) {
            setOpenError(true)
            setErrorMessage('' + error)
        }
        setIsLoadingModal(false)
    })
  }

  const confirmSubmitPullRequest = () => {
    setIsLoadingModal(true)

    axios.post(`/applications/${submitPullRequest._id}/submit-pr`, { pr })
    .then(({ data: { message } }) => {
      setSubmitPullRequest(null)
      if(message) {
        enqueueSnackbar(message, { variant: 'success' })
      }else {
        enqueueSnackbar('PR submitted successfully.', { variant: 'success' })
      }
      setIsLoadingModal(false)
      refetch()
    })
    .catch(error => {
        console.log('error', error)        
        if (error?.response?.status === 401) {
            setOpenErrorUnauthorized(true)
            if(error?.response?.data?.error) {
                setErrorUnauthorizedMessage(error?.response?.data?.error)
            }else {
                setErrorUnauthorizedMessage('Authentication is required to make this request')
            }
        }else if(error?.response?.data?.error) {
            setOpenError(true)
            setErrorMessage(error?.response?.data?.error)
        }else if(error) {
            setOpenError(true)
            setErrorMessage('' + error)
        }
        setIsLoadingModal(false)
    })
  }
  
  const onCloseSnackbarErrorUnauthorized = () => {
    setOpenErrorUnauthorized(false)
    history.push('/sign-in')
  }

  return (
      <>
      <div style={{flexGrow: 1, marginLeft: 5}}>
        {applications.map(app => {
          const activeApplication = (app.status === 'active')

          const showAvailability = activeApplication
          && ['open', 'pre-start'].includes(app.devRequest.status)
          && (app.bidValue <= app.devRequest.contributionAmount || app.devRequest.status === 'pre-start')
          && type == 'byUser'
          && !app.availableToStart
      
          const showSubmitPR = activeApplication
          && app.devRequest.status == 'in-progress'
          && app.devRequest.assignedApplication == app._id
          && type == 'byUser'

          const deadlineDate = type == 'byUser' && app.devRequest.timeline.startDate && new Date(app.devRequest.timeline.startDate)
          if (deadlineDate) deadlineDate.setDate(deadlineDate.getDate() + 14)

          return (
            <div key={app._id} style={{ borderBottomStyle: 'solid', borderBottomWidth: 1, borderColor: 'lightgray', padding: 10}}>
                <MuiLink variant="h6" component={Link} to={`dev-requests/${app.devRequest._id}`}>{type == 'byUser' ? app.devRequest.type + '/' + app.devRequest.title : app.user.name}</MuiLink>
                {type == 'byUser' ? <Typography variant="caption" style={{backgroundColor: 'red', color: 'white', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 4, marginLeft: 5}}>{activeApplication ? app.devRequest.status : app.status}</Typography> : null}
                {type == 'byUser' ? <Typography variant="body2"><MuiLink target="_blank" href={`https://${app.devRequest.project}`}>{app.devRequest.project}</MuiLink></Typography> : null}
                {activeApplication && <div>
                  {type == 'byUser' && <Typography variant='caption' style={{ marginRight: 20 }}><span style={{ fontWeight: 'bold' }}>Target Value:</span> USD {app.bidValue && app.bidValue > app.devRequest.contributionAmount ? app.bidValue.toFixed(2) : app.devRequest.contributionAmount}</Typography>}
                  {app.devRequest.status == 'pre-start' && <Typography variant="caption" style={{ marginRight: 20 }}>{app.availableToStart ? 'Available' : 'Not Available'}</Typography>}
                  {type == 'byUser' && app.devRequest.timeline.startDate && <Typography variant="caption" style={{ marginRight: 20 }}><span style={{ fontWeight: 'bold' }}>Start Date:</span> {new Date (app.devRequest.timeline.startDate).toDateString()}</Typography>}
                  {app.devRequest.status == 'pr-under-review' && <Typography variant="caption" style={{ marginRight: 20 }}><span style={{ fontWeight: 'bold' }}>Pull Request: </span><MuiLink target="_blank" href={`https://${app.devRequest.project}/pull/${app.devRequest.prNumber}`}>#{app.devRequest.prNumber}</MuiLink></Typography>}
                </div>}
                {showAvailability && <div style={{display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', backgroundColor: '#00000010' }}>
                    <Typography style={{marginLeft: 12, marginTop: 12}} variant="body2">Yes! You are eligible to deliver this Request! Confirm you availability if you can deliver it in one sprint (two weeks from now).</Typography>
                    <Button startIcon={<Check />} style={{marginLeft: 10}} onClick={() => setApplicationAvailability(app)}>I'm available</Button>
                  </div>}
                  {showSubmitPR && <div style={{display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between', backgroundColor: '#00000010' }}>
                    <Typography style={{marginLeft: 12, marginTop: 12}} variant="body2">Submit until {deadlineDate && deadlineDate.toDateString()} the Pull Request that implements the feature.</Typography>
                    <Button onClick={() => setSubmitPullRequest(app)}>Submit Pull Request</Button>
                </div>}
            </div>
          )
        })}
        <Dialog open={applicationAvailability !== null} aria-labelledby="form-dialog-title">
            <div style={{padding: 20, display: 'flex', flexDirection: 'column'}}>
                <h3>Confirm you are available to start</h3>
                <p>By confirming your availability, you are running to be assigned for this dev request. Keep in mind that if you're selected you will have two weeks to deliver the request.</p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                  <Button style={{marginRight: 10}} onClick={() => setApplicationAvailability(null)} variant="outlined">Cancel</Button>
                  <Button startIcon={isLoadingModal ? <CircularProgress size={26} color="secondary" /> : <Check />} onClick={confirmAvailability} style={{alignSelf: 'end'}}>I'm available</Button>
                </div>
            </div>
        </Dialog>
        <Dialog open={submitPullRequest !== null} aria-labelledby="form-dialog-title">
            <div style={{padding: 20, display: 'flex', flexDirection: 'column'}}>
                <h3>Submit Pull Request</h3>
                <p>By submitting you Pull Request, you will receive the prize from Dev Request when the PR is accepted.</p>
                <TextField
                  style={{width: 150}}
                  label='Pull Request Number' 
                  required 
                  value={pr}
                  onChange={(e) => setPr(e.target.value)}
                  InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>, inputComponent: NumberFormatCustom,
                  }}
                />
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                  <Button style={{marginRight: 10}} onClick={() => setSubmitPullRequest(null)} variant="outlined">Cancel</Button>
                  <Button startIcon={isLoadingModal ? <CircularProgress size={26} color="secondary" /> : <ArrowUpward />} onClick={confirmSubmitPullRequest} style={{marginLeft: 10}}>Confirm Pull Request</Button>
                </div>
            </div>
        </Dialog>
      </div>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: 350 }} >
          {'Error: ' + errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
          open={openErrorUnauthorized}
          autoHideDuration={6000}
          onClose={() => onCloseSnackbarErrorUnauthorized(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
          <Alert severity="error" sx={{ width: 350 }} >
              {errorUnauthorizedMessage}
          </Alert>
      </Snackbar>
      </>
  )
}

export default ApplicationList;