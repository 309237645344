import React, { useState, useEffect } from 'react'
import { FormControlLabel, RadioGroup, FormControl, FormLabel, Button, DialogContent, DialogTitle, DialogActions, Radio, Snackbar, Alert, Box, CircularProgress, InputLabel} from '@mui/material'
import axios from 'axios'
import { useLocation, useParams, useHistory } from 'react-router-dom'

const Contribution = () => {

    const [devRequest, setDevRequest] = useState()
    const history = useHistory()
    let { devRequestId } = useParams()

    const [contributionAmount, setContributionAmount] = useState('10')   
    
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchDevRequest = () => {
        setIsLoading(true)

        axios.get('/dev-requests/' + devRequestId)
            .then(({ data: { devRequest } }) => {
                setDevRequest(devRequest)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchDevRequest()
    }, [])

    const submit = (e) => {
        e.preventDefault()
        //if (!validate()) return

        setIsLoading(true)

        const contribution = {
            contributionAmount: Number(contributionAmount),
            devRequestId
        }
        axios.post(`/contributions/create-checkout-session`, contribution)
        .then(({ data: { url } }) => {
            window.location.href = url
            setIsLoading(false)
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoading(false)
        })
    }

    const handleChange = (event) => {
        setContributionAmount(event.target.value)
    };

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    return (
        <>
            {!isLoading && (
                <form onSubmit={submit}>
                    <DialogTitle id="form-dialog-title">Select the amount you want to contribute to '{devRequest?.type} / {devRequest?.title}'</DialogTitle>
                    <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Contribution amount</FormLabel>
                                <RadioGroup
                                    aria-label="contribution"
                                    value={contributionAmount}
                                    onChange={handleChange}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="10" control={<Radio />} label="US$ 10" />
                                    <FormControlLabel value="20" control={<Radio />} label="US$ 20" />
                                    <FormControlLabel value="50" control={<Radio />} label="US$ 50" />
                                    <FormControlLabel value="100" control={<Radio />} label="US$ 100" />
                                </RadioGroup>
                            </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => history.goBack()} color="primary">
                        Cancel
                        </Button>
                        <Button type="submit" color="secondary">
                            Pay
                        </Button>
                    </DialogActions>
                </form>
            )}
            {isLoading && 
                <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                    <div>
                        <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                        <InputLabel>Loading...</InputLabel>
                    </div>
                </Box>
            } 
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Contribution