import React, { useState } from "react"
import { TextField, Select, Button, List, ListItem, ListItemText, Divider } from "@mui/material"
import Profile from "./profile"
import Transactions from "./transactions"
import TransferMethod from "./transfer-method"

const Settings = () => {

    const [selectedItem, setSelectedItem] = useState()


    return (
        <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{display:'flex', flexDirection:'column', width: '16%'}}>
                <List component="nav" aria-label="mailbox folders">
                    <ListItem 
                        button
                        onClick={() => setSelectedItem('profile')}
                    >
                        <ListItemText primary="Profile" />
                    </ListItem>
                    <Divider />
                    <ListItem 
                        button 
                        divider
                        onClick={() => setSelectedItem('transactions')}
                    >
                        <ListItemText primary="Transactions" />
                    </ListItem>
                    <ListItem 
                        button
                        onClick={() => setSelectedItem('transfer-method')}
                    >
                        <ListItemText primary="Transfer Method" />
                    </ListItem>
                    <Divider light />
                    <ListItem button>
                        <ListItemText primary="Delete Account" />
                    </ListItem>
                </List>
            </div>
            <div style={{ paddingLeft: '6%', paddingRight: '6%', width: '65%'}}>
                {selectedItem === 'profile' && (
                    <Profile />
                )}
                {selectedItem === 'transactions' && (
                    <Transactions />
                )}
                {selectedItem === 'transfer-method' && (
                    <TransferMethod />
                )}
            </div>
        </div>
    )
}

export default Settings