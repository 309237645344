import React, { useEffect, useState } from 'react'
import ApplicationList from './application-list'
import axios from 'axios'
import {Box, Tabs, Tab, Snackbar, Alert, CircularProgress, InputLabel } from '@mui/material'
import { useHistory } from 'react-router';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
                <h3>{children}</h3>
            </Box>
        )}
        </div>
    );
}

const Applications = () => {

    const history = useHistory()

    const [applications, setApplications] = useState([])
    const [tab, setTab] = useState(0)
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchApplications = () => {
        setIsLoading(true)

        axios.get('/applications')
            .then(({ data: { rows } }) => {
                setApplications(rows)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchApplications()
    }, [])

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>            
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Applied" {...a11yProps(0)} />
                        <Tab label="Active" {...a11yProps(1)} />
                        <Tab label="Completed" {...a11yProps(2)} />
                        <Tab label="Archived" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                {!isLoading && (
                    <div>
                        <TabPanel value={tab} index={0}>
                            <ApplicationList applications={applications.filter(application => application.status === 'active' && ['open', 'pre-start'].includes(application.devRequest.status))} refetch={fetchApplications} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <ApplicationList applications={applications.filter(application => application.status === 'active' && ['in-progress', 'pr-under-review', 'pr-pending-fixes'].includes(application.devRequest.status))} refetch={fetchApplications} />
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <ApplicationList applications={applications.filter(application => application.status === 'completed' && ['pr-merged'].includes(application.devRequest.status))} refetch={fetchApplications} />
                        </TabPanel>
                        <TabPanel value={tab} index={3}>
                            <ApplicationList applications={applications.filter(application => ['missed-deadline', 'not-selected', 'cancelled'].includes(application.status))} refetch={fetchApplications} />
                        </TabPanel>
                    </div>
                )}
            </div>
            {isLoading && 
                <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                    <div>
                        <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                        <InputLabel>Loading...</InputLabel>
                    </div>
                </Box>
            }  
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Applications