import HelpIcon from '@mui/icons-material/HelpOutline';
import { Alert, Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, Radio, RadioGroup, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import CurrencyFormatCustom from './utils/CurrencyFormatCustom'
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Subscribe = () => {

    const history = useHistory()
    const [selectedPlanType, setSelectedPlanType] = useState('')
    const [plans, setPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState('')
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [openWarning, setOpenWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState(null)
    const [otherValues, setOtherValues] = useState('')
    const [serviceFees, setServiceFees] = useState('15')
    const [isLoading, setIsLoading] = useState(false)
 
    const submit = (e) => {
        e.preventDefault()

        if (!selectedPlanType) {
            setOpenWarning(true)
            setWarningMessage('Select your plan type.')
            return
        }
        if (!selectedPlan) {
            setOpenWarning(true)
            setWarningMessage('Select your contribution amount.')
            return
        }else {
            if (selectedPlan === 'other-values' && Number(otherValues) <= 100) {
                setOpenWarning(true)
                setWarningMessage('Value must be greather than minimum of US$ 100.')
                return
            }
        }

        const contribution = {
            planId: selectedPlan,
            value: Number(otherValues)
        }
        axios.post(`/subscriptions/create-checkout-session`, contribution)
            .then(({ data: { url } }) => {
                localStorage.setItem('check-payment', true)
                window.location.href = url
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
            })
    }

    const fetchPlans = (planType) => {
        setIsLoading(true)

        setSelectedPlanType(planType)
        setSelectedPlan('')
        setOtherValues('')
        setPlans([])

        axios.get('/plans?recurrent=true&type=' + planType)
            .then(({ data }) => {
                setPlans(data.plans)
                setServiceFees(data.serviceFees)
                if(data.plans && data.plans.length === 1) {
                    setSelectedPlan(data.plans[0]._id)
                }
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    useEffect(() => {
        //fetchPlans(selectedPlanType)
    }, [])

    const handleChange = (event) => {
        setSelectedPlan(event.target.value)
    };

    return (
        <>
            <form onSubmit={submit}>
                <DialogTitle id="form-dialog-title">Subscribe to provide a monthly contribution</DialogTitle>
                <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Plan type</FormLabel>
                            <RadioGroup
                                aria-label="Plan Type"
                                value={selectedPlanType}
                                onChange={(event) => fetchPlans(event.target.value)}
                                name="radio-buttons-group"
                                row={true}
                            >
                                <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                                <FormControlLabel value="enterprise" control={<Radio />} label="Enterprise" />
                                <FormControlLabel value="other-values" control={<Radio />} label="Other Values" />
                            </RadioGroup>
                        </FormControl>
                        {!isLoading && selectedPlanType !== '' && plans && (
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Contribution amount</FormLabel>
                                {selectedPlanType !== 'other-values' && (
                                    <RadioGroup
                                        aria-label="contribution"
                                        value={selectedPlan}
                                        onChange={handleChange}
                                        name="radio-buttons-group"
                                    >
                                        {plans && plans.map(plan => (
                                            <div>
                                                <FormControlLabel key={plan._id} value={plan._id} control={<Radio />} 
                                                    label={`US$ ${plan.price} (+ US$ ${plan.price * serviceFees / 100} of service fees) / month`} />
                                                <Tooltip title={`${plan.name}${plan.description !== '' ? ' - ' + plan.description : ''}`}>
                                                    <IconButton>
                                                        <HelpIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        ))}
                                    </RadioGroup>
                                )}
                                {selectedPlanType === 'other-values' && (
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'end'}}>
                                        <TextField
                                            label='Value in USD'
                                            InputProps={{
                                                inputComponent: CurrencyFormatCustom,
                                            }}
                                            value={otherValues}
                                            onChange={(e) => setOtherValues(e.target.value)}
                                        />
                                        <div style={{marginLeft: 10}}>
                                            <FormLabel component="legend">(+ {serviceFees}% of service fees) / month</FormLabel>
                                        </div>
                                    </div>
                                )}
                            </FormControl>
                        )}
                        {isLoading && 
                            <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                                <div>
                                    <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                                    <InputLabel>Loading...</InputLabel>
                                </div>
                            </Box>
                        }  
                </DialogContent>
                <DialogActions>
                    <Button style={{marginRight: 10}} variant="outlined" onClick={() => history.goBack()} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit">
                        Pay
                    </Button>
                </DialogActions>
                <Snackbar
                    open={openWarning}
                    autoHideDuration={6000}
                    onClose={() => setOpenWarning(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity="warning" sx={{ width: 350 }} >
                        {warningMessage}
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={openError}
                    autoHideDuration={6000}
                    onClose={() => setOpenError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity="error" sx={{ width: '100%' }} >
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={openErrorUnauthorized}
                    autoHideDuration={6000}
                    onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity="error" sx={{ width: 350 }} >
                        {errorUnauthorizedMessage}
                    </Alert>
                </Snackbar>
            </form>
        </>
    )
}

export default Subscribe