import React, { useEffect } from 'react';
import { AppBar, Toolbar, IconButton, MenuItem, Typography, Button, Snackbar, Alert } from '@mui/material';
import MuiLink from '@mui/material/Link'
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import logo from './images/oc-logo-beta.png';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router';
import { signOut, githubAuthProvider } from './firebase'
import { GithubAuthProvider, linkWithPopup } from "firebase/auth";
import axios from 'axios'

export default function MenuAppBar({ auth }) {
  const history = useHistory()
  
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [state, setState] = React.useState(false)
  const [availableFunds, setAvailableFunds] = React.useState(0)
  const open = Boolean(anchorEl);

  const [openSuccess, setOpenSuccess] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState(null)

  useEffect(() => {
    if (auth) {
      axios.get('users/my-profile').then(({ data }) => {
        setAvailableFunds((data.profile.sponsorBalance + data.profile.developerBalance).toFixed(2))
      })
    }
  }, [auth])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
    signOut().then(() => {
      history.push('/sign-in')
      setAnchorEl(null)
    })
  };

  const handleSettingsClick = () => {
    history.push('/settings')
    setAnchorEl(null);
  };

  const handleConnectGithub = () => {
    console.log('entrou aqui')
    linkWithPopup(auth, githubAuthProvider).then(result => {
      const credential = githubAuthProvider.credentialFromResult(result)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      axios.post('users').then(() => {
        setOpenSuccess(true)
        setSuccessMessage('Account successfully linked to GitHub!')
      })
    }).catch((error) => {
      console.log(error)
      // Handle Errors here.
      // ...
    });
    setAnchorEl(null);
  }

  return (
    <>
      <div>
        <AppBar color='default' position="static">
          <Toolbar>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <a 
                  href="https://opencoders.club"
                >
                  <img
                    src={logo} alt="Logo" height="50"
                  /> 
                </a>
                <MuiLink color='primary' style={{ marginLeft: 15, fontWeight: 'bold' }} underline='none' variant='body1' component={Link} to='/dev-requests'>Requests</MuiLink>
                <MuiLink color='primary' style={{ marginLeft: 15, fontWeight: 'bold' }} underline='none' variant='body1' component={Link} to='/applications'>Applications</MuiLink>
                <MuiLink color='primary' style={{ marginLeft: 15, fontWeight: 'bold' }} underline='none' variant='body1' component={Link} to='/contributions'>Contributions</MuiLink>
                <MuiLink color='primary' style={{ marginLeft: 15, fontWeight: 'bold' }} underline='none' variant='body1' component={Link} to='/sponsorship'>Sponsorship</MuiLink>
              </div>
              {auth ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', }}>
                    <Typography variant='body1' style={{ marginRight: 5 }}>Hello, {auth.displayName}!</Typography>
                    <Typography variant='caption'>Available Funds: USD {availableFunds}</Typography>
                  </div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle color="primary" />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
                    <MenuItem onClick={handleConnectGithub}>Connect to Github Account</MenuItem>
                    <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                  </Menu>
                </div>
              ) : (
                <div style={{ display: 'flex' }}>
                  <Button style={{marginRight: 5}} variant="text" component={Link} to="/sign-in">Login</Button>
                  <Button component={Link} to="/sign-up">Sign Up</Button>
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={() => setOpenSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: 350 }} >
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
