import { Save } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { Alert, Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, Radio, RadioGroup, Snackbar, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

const TransferMethod = () => {
    const [selectedTransferMethod, setSelectedTransferMethod] = useState('')
    const [recipientName, setRecipientName] = useState('')
    const [routingNumber, setRoutingNumber] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [country, setCountry] = useState('')
    const [recipientAddressLine1, setRecipientAddressLine1] = useState('')
    const [recipientAddressLine2, setRecipientAddressLine2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [postalZipCode, setPostalZipCode] = useState('')
    const [recipientType, setRecipientType] = useState('person')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [recipientLegalAddress, setRecipientLegalAddress] = useState('')
    const [apartmentSuiteFloor, setApartmentSuiteFloor] = useState('')
    const [recipientBankSwiftCode, setRecipientBankSwiftCode] = useState('')
    const [recipientIban, setRecipientIban] = useState('')
    const [transferMethod, setTransferMethod] = useState()
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [openWarning, setOpenWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState(null)
    const [openInfo, setOpenInfo] = useState(false)
    const [infoMessage, setInfoMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    let history = useHistory()

    const fetchTransferMethod = () => {
        setIsLoading(true)

        axios.get('/transfer-methods')
            .then(({ data: { transferMethod } }) => {
                setTransferMethod(transferMethod)
                if(transferMethod) {
                    setSelectedTransferMethod(transferMethod.type)
                    setRecipientName(transferMethod.recipientName)
                    setRoutingNumber(transferMethod.routingNumber)
                    setAccountNumber(transferMethod.accountNumber)
                    setCountry(transferMethod.country)
                    setRecipientAddressLine1(transferMethod.recipientAddressLine1)
                    setRecipientAddressLine2(transferMethod.recipientAddressLine2)
                    setCity(transferMethod.city)
                    setState(transferMethod.state)
                    setPostalZipCode(transferMethod.postalZipCode)
                    setRecipientType(transferMethod.recipientType)
                    setPhoneNumber(transferMethod.phoneNumber)
                    setRecipientLegalAddress(transferMethod.recipientLegalAddress)
                    setApartmentSuiteFloor(transferMethod.apartmentSuiteFloor)
                    setRecipientBankSwiftCode(transferMethod.recipientBankSwiftCode)
                    setRecipientIban(transferMethod.recipientIban)
                }
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchTransferMethod()
    }, [])

    const onChangeTransferMethod = (event) => {
        if(transferMethod) {
            const continueApp = window.confirm('Do you really want to change your transfer method?')
    
            if (!continueApp) return
        }

        setIsLoading(true)

        setSelectedTransferMethod(event.target.value)
        
        clearTransferMethodFields(event.target.value)

        setTimeout(() => {
            setIsLoading(false)
        }, 300)
    }

    const clearTransferMethodFields = (type) => {
        setRecipientName('')
        setCountry('')
        setCity('')
        setState('')
        setPostalZipCode('')
        if(type === 'us-bank-transfer') {
            setRecipientType('')
            setPhoneNumber('')
            setRecipientLegalAddress('')
            setApartmentSuiteFloor('')
            setRecipientBankSwiftCode('')
            setRecipientIban('')
        }else if(type === 'international-wire-transfer') {
            setRecipientType('person')
            setRecipientAddressLine1('')
            setRecipientAddressLine2('')
            setRoutingNumber('')
            setAccountNumber('')
        }
    }

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    const submit = (e) => {
        e.preventDefault()
        if (!selectedTransferMethod){
            setOpenWarning(true)
            setWarningMessage('Select your transfer method.')
            return
        }
        
        const continueApp = window.confirm('Do you want to save changes?')

        if (!continueApp) return

        setIsLoadingButton(true)

        const transferMethod = {
            type: selectedTransferMethod,
            recipientName: recipientName,
            routingNumber: routingNumber,
            accountNumber: accountNumber,
            country: country,
            recipientAddressLine1: recipientAddressLine1,
            recipientAddressLine2: recipientAddressLine2,
            city: city,
            state: state,
            postalZipCode: postalZipCode,
            recipientType: recipientType,
            phoneNumber: phoneNumber,
            recipientLegalAddress: recipientLegalAddress,
            apartmentSuiteFloor: apartmentSuiteFloor,
            recipientBankSwiftCode: recipientBankSwiftCode,
            recipientIban: recipientIban
        }
        axios.post('/transfer-methods', transferMethod)
        .then(() => {
            setOpenInfo(true)
            setInfoMessage('Transfer method saved successfully.')
            setIsLoadingButton(false)
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoadingButton(false)
        })
    }

    return (
        <>
            <h3>Transfer Method</h3>
            <form onSubmit={submit}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Select your transfer method:</FormLabel>
                    <RadioGroup
                        aria-label="transfer-method"
                        value={selectedTransferMethod}
                        onChange={onChangeTransferMethod}
                        name="radio-buttons-group"
                    >
                        <div style={{display:'flex'}}>
                            <FormControlLabel value={'us-bank-transfer'} control={<Radio />} label={'US bank transfer.'} />
                            <Tooltip title="Delivery within 1 business day.">
                                <IconButton>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{display:'flex'}}>
                            <FormControlLabel value={'international-wire-transfer'} control={<Radio />} label={'International Wire transfer.'} />
                            <Tooltip title="Delivery within 1-3 business days.">
                                <IconButton>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </RadioGroup>
                </FormControl>
                {!isLoading && selectedTransferMethod === 'us-bank-transfer' && (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <FormControl component="fieldset">
                            <FormLabel style={{marginTop: 20, fontWeight: 'bold'}}>Recipient details</FormLabel>
                            <TextField
                                required
                                label='Recipient Name'
                                value={recipientName}
                                onChange={(e) => setRecipientName(e.target.value)}
                            />
                            <TextField
                                required
                                label='Country or Territory'
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                            <TextField
                                required
                                label='Recipient&#39;s Address Line 1'
                                value={recipientAddressLine1}
                                onChange={(e) => setRecipientAddressLine1(e.target.value)}
                            />
                            <TextField
                                required
                                label='Recipient&#39;s Address Line 2'
                                value={recipientAddressLine2}
                                onChange={(e) => setRecipientAddressLine2(e.target.value)}
                            />
                            <TextField
                                required
                                label='City'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                            <TextField
                                required
                                label='State'
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                            <TextField
                                required
                                label='Postal/Zip Code'
                                value={postalZipCode}
                                onChange={(e) => setPostalZipCode(e.target.value)}
                            />
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel style={{marginTop: 20, fontWeight: 'bold'}}>Bank details</FormLabel>
                            <TextField
                                required
                                label='Routing Number'
                                value={routingNumber}
                                onChange={(e) => setRoutingNumber(e.target.value)}
                            />
                            <TextField
                                required
                                label='Account Number'
                                value={accountNumber}
                                onChange={(e) => setAccountNumber(e.target.value)}
                            />
                        </FormControl>
                    </div>
                )}
                {!isLoading && selectedTransferMethod === 'international-wire-transfer' && (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <FormControl component="fieldset">
                            <FormLabel style={{marginTop: 20, marginBottom: 20, fontWeight: 'bold'}}>Recipient details</FormLabel>
                            <RadioGroup
                                aria-label="Recipient Type"
                                value={recipientType}
                                onChange={(event) => setRecipientType(event.target.value)}
                                name="radio-buttons-group"
                                row={true}
                            >
                                <FormControlLabel value="person" control={<Radio />} label="Person" />
                                <FormControlLabel value="business" control={<Radio />} label="Business" />
                            </RadioGroup>
                            <TextField
                                required
                                label='Recipient Name'
                                value={recipientName}
                                onChange={(e) => setRecipientName(e.target.value)}
                            />
                            <TextField
                                required
                                label='Phone Number (with country code)'
                                value={phoneNumber}
                                placeholder='+1 (000) 000 - 0000'
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <TextField
                                required
                                label='Country'
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                            <TextField
                                required
                                label='Recipient&#39;s Legal Address'
                                value={recipientLegalAddress}
                                onChange={(e) => setRecipientLegalAddress(e.target.value)}
                            />
                            <TextField
                                label='Apartment / Suite / Floor'
                                value={apartmentSuiteFloor}
                                onChange={(e) => setApartmentSuiteFloor(e.target.value)}
                            />
                            <TextField
                                required
                                label='City'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                            <TextField
                                required
                                label='State / Province / Region'
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                            <TextField
                                required
                                label='Postal / Zip Code'
                                value={postalZipCode}
                                onChange={(e) => setPostalZipCode(e.target.value)}
                            />
                        </FormControl>

                        <FormControl component="fieldset">
                            <FormLabel style={{marginTop: 20, fontWeight: 'bold'}}>Bank details</FormLabel>
                            <TextField
                                required
                                label='Recipient Bank SWIFT Code'
                                value={recipientBankSwiftCode}
                                placeholder='Enter an 8- or 11-digit code'
                                onChange={(e) => setRecipientBankSwiftCode(e.target.value)}
                            />
                            <TextField
                                required
                                label='Recipient IBAN'
                                value={recipientIban}
                                onChange={(e) => setRecipientIban(e.target.value)}
                            />
                        </FormControl>
                    </div>
                )}
                {isLoading && 
                    <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                        <div>
                            <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                            <InputLabel>Loading...</InputLabel>
                        </div>
                    </Box>
                }
                {!isLoading && selectedTransferMethod !== '' && (<div style={{marginTop: 20}}>
                    <Button startIcon={isLoadingButton ? <CircularProgress size={26} color="secondary" /> : <Save />} type="submit">
                        Save
                    </Button>
                </div>
                )}
            </form>
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openWarning}
                autoHideDuration={6000}
                onClose={() => setOpenWarning(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="warning" sx={{ width: 350 }} >
                    {warningMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openInfo}
                autoHideDuration={6000}
                onClose={() => setOpenInfo(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="info" sx={{ width: 350 }} >
                    {infoMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default TransferMethod