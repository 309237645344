'use strict'
import React, { useEffect, useState } from 'react'
import MenuAppBar from './menu-app-bar'
import SignIn from './sign-in'
import { createTheme, ThemeProvider } from '@mui/material';
import { Container, CssBaseline } from '@mui/material'
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import axios from 'axios'
import DevRequest from './dev-request/dev-request'
import Applications from './application/applications'
import Contribution from './contribution/contribution'
import Apply from './application/apply'
import { auth } from './firebase'
import DevRequests from './dev-request/dev-requests';
import Settings from './settings/settings'
import Projects from './projects/projects'
import Subscribe from './subscribe';
import Contributions from './contribution/contributions';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import DevRequestCreate from './dev-request/dev-request-create';
import { useAuthState } from 'react-firebase-hooks/auth';
import { SnackbarProvider } from 'notistack';

const App = (props) => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#115571'
      },
      secondary: {
        main: '#31afb4'
      }
    },
    components: {
      MuiContainer: {
        root: {
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        },
        styleOverrides: {
          root: {
            marginTop: 8,
            marginBottom: 4
          }
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard'
        },
        styleOverrides: {
          root: {
            marginTop: 8,
            marginBottom: 4
          }
        }
      },
      MuiAutocomplete: {
        defaultProps: {
          variant: 'standard'
        },
        styleOverrides: {
          root: {
            marginTop: 8,
            marginBottom: 4
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained'
        },
        styleOverrides: {
          root: {
            marginTop: 5,
            marginBottom: 5,
            paddingLeft: 20,
            paddingRight: 20,
            textTransform: 'none',
            fontWeight: 'bold'
          }
        }
      },
      MuiTableCell: {
        head: {
          fontWeight: 'bold'
        }
      }
    }
  })

  const { pathname } = useLocation()

  const [user, loading, error] = useAuthState(auth)
  const [userLoaded, setUserLoaded] = useState(false)

  useEffect(() => {
    console.log(process.env.DEPLOY_ENV)
    if (process.env.DEPLOY_ENV == 'production') {
      axios.defaults.baseURL = 'https://open-coders-64ap2.ondigitalocean.app/'
    } else if (process.env.DEPLOY_ENV == 'staging') {
      axios.defaults.baseURL = 'https://opencoders-staging-app-eef2y.ondigitalocean.app/'
    } else axios.defaults.baseURL = 'http://localhost:5000'

    if (user) {
      user.getIdToken().then(token => {
        console.log('token refreshed:', token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        setUserLoaded(true)
      })
    } else if (!loading) setUserLoaded(true)
  }, [user, loading])

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <MenuAppBar auth={userLoaded && user} />
        <Container style={{padding: '20px'}}>
          {userLoaded && (
            <Switch>
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route path="/applications">
                <Applications />
              </Route>
              <Route path="/dev-requests/create">
                <DevRequestCreate />
              </Route>
              <Route path="/dev-requests/:devRequestId/contribute">
                <Contribution />
              </Route>
              <Route path="/dev-requests/:devRequestId/apply">
                <Apply />
              </Route>
              <Route path="/dev-requests/:devRequestId">
                <DevRequest />
              </Route>
              <Route path="/dev-requests">
                <DevRequests />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/sponsorship">
                <Projects />
              </Route>
              <Route path="/subscribe">
                <Subscribe />
              </Route>
              <Route path="/contributions">
                <Contributions />
              </Route>
              <Route exact path="/">
                <Redirect to="/dev-requests" />
              </Route>
              <Route path="/sign-in" component={SignIn} />
              <Route path="/sign-up" >
                <SignIn mode="sign-up" />
              </Route>
            </Switch>
          )}
        </Container>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
