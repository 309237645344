import { Alert, Box, CircularProgress, InputLabel, Snackbar } from "@mui/material";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ContributionList from './contribution-list';

const Contributions = () => {

    const [contributions, setContributions] = useState([])
    const history = useHistory()

    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchContributions = () => {
        setIsLoading(true)

        axios.get('/contributions/my')
            .then(({ data: { rows } }) => {
                setContributions(rows)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }    

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    useEffect(() => {
        fetchContributions()
    }, [])

    return (
        <>
            {!isLoading && (
                <ContributionList contributions={contributions}/> 
            )}
            {isLoading && 
                <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                    <div>
                        <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                        <InputLabel>Loading...</InputLabel>
                    </div>
                </Box>
            }      
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Contributions