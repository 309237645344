import React from 'react'
import DevRequestForm from "./dev-request-form"


const DevRequestCreate = () => {

    return (
        <DevRequestForm />
    )
}

export default DevRequestCreate