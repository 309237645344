import { GitHub, Google, LockOpen } from '@mui/icons-material'
import { Alert, Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid, Snackbar, TextField, Typography } from '@mui/material'
import MuiLink from '@mui/material/Link'
import axios from 'axios'
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth"
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from "react-router-dom"
import { auth, googleAuthProvider } from './firebase'
import logo from './images/open-coders-logo-beta.png'
import SignUp from './sign-up'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MuiLink color="secondary" underline="none" style={{ fontWeight: 'bold' }} href="https://opencoders.club/">
        opencoders.club
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn = ({ mode = 'sign-in' }) => {

  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)

  const [openInfoApprovalPending, setOpenInfoApprovalPending] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
  const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)

  const login = (event) => {
    event.preventDefault();
    setWaiting(true)

    signInWithEmailAndPassword(auth, email, password)
      .then(() => signInProcess())
      .catch(error => {
        setOpenError(true)
        setErrorMessage('Invalid Credentials')
        setWaiting(false)
      })
  }

  const signInProcess = () => {
    auth.currentUser.getIdTokenResult(true).then(tokenResult => {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenResult.token
      if (tokenResult.claims.userCreated) {
        if (tokenResult.claims.status === 'accepted') {
          history.push('/dev-requests')
        } else {
          setOpenInfoApprovalPending(true)
        }
        setWaiting(false)
      } else {
        axios.post('users').then(() => {
          auth.currentUser.getIdTokenResult(true).then(tokenResult => {
            if (tokenResult.claims.status === 'accepted') {
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenResult.token
              history.push('/dev-requests')
            } else {
              setOpenInfoApprovalPending(true)
            }
            setWaiting(false)
          }).catch(error => {
            if (error?.response?.status === 401) {
              setOpenErrorUnauthorized(true)
              if(error?.response?.data?.error) {
                  setErrorUnauthorizedMessage(error?.response?.data?.error)
              }else {
                  setErrorUnauthorizedMessage('Authentication is required to make this request')
              }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setWaiting(false)
          })
        }).catch(error => {
          if (error?.response?.status === 401) {
            setOpenErrorUnauthorized(true)
            if(error?.response?.data?.error) {
                setErrorUnauthorizedMessage(error?.response?.data?.error)
            }else {
                setErrorUnauthorizedMessage('Authentication is required to make this request')
            }
          }else if(error?.response?.data?.error) {
              setOpenError(true)
              setErrorMessage(error?.response?.data?.error)
          }else if(error) {
              setOpenError(true)
              setErrorMessage('' + error)
          }
          setWaiting(false)
        })
      }
    })
  }

  const signInWithGoogle = () => {
    googleAuthProvider.addScope('profile')
    googleAuthProvider.addScope('email')
    signInWithPopup(auth, googleAuthProvider).then(() => signInProcess())
  }

  const signInWithGitHub = () => {
    setErrorMessage('Github authentication is coming soon! Use Google or email authentication for now.')
    setOpenError(true)
    /*provider.addScope('public_repo')
    signInWithPopup(auth, githubAuthProvider).then((credentials) => {
      credentials.user.getIdToken().then(token => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        axios.post('users').then(() => {
          history.push('/dev-requests')
        })
      })
    })*/
  }

  const onCloseSnackbarErrorUnauthorized = () => {
      setOpenErrorUnauthorized(false)
      history.push('/sign-in')
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={logo} style={{ marginBottom: 30 }} alt="Logo" height="120" />
          <Button
            onClick={signInWithGitHub}
            fullWidth
            style={{ backgroundColor: 'black' }}
            startIcon={<GitHub />}
          >Continue With GitHub</Button>
          <Button
            onClick={signInWithGoogle}
            fullWidth
            style={{ backgroundColor: '#4c8bF5' }}
            startIcon={<Google />}
          >Continue With Google</Button>
          <Typography style={{ margin: 10 }} variant="h6">or</Typography>
          {mode === 'sign-in' ? (
            <form onSubmit={login} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={waiting ? <CircularProgress size={26} color="secondary" /> : <LockOpen />}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <MuiLink underline="none" component={Link} to="#" variant="caption">
                    Forgot password?
                  </MuiLink>
                </Grid>
                <Grid item>
                  <MuiLink underline="none" component={Link} to="/sign-up" variant="caption">
                    {"Don't have an account? Sign Up"}
                  </MuiLink>
                </Grid>
              </Grid>
            </form>
          ) : (
            <SignUp />
          )}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
      <Snackbar
        open={openInfoApprovalPending}
        autoHideDuration={6000}
        onClose={() => setOpenInfoApprovalPending(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="info" sx={{ width: 350 }} >
          Your approval to access Open Coders is pending. Contact our team for more information: support@opencoders.club
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: 350 }} >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
          open={openErrorUnauthorized}
          autoHideDuration={6000}
          onClose={() => onCloseSnackbarErrorUnauthorized(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
          <Alert severity="error" sx={{ width: 350 }} >
              {errorUnauthorizedMessage}
          </Alert>
      </Snackbar>
    </>
  )
}

export default SignIn;