import { Paid } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, InputLabel, Paper, Radio, RadioGroup, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

const Transactions = () => {
    const [transactions, setTransactions] = useState([])
    const [developerBalance, setDeveloperBalance] = useState(0)
    const [sponsorBalance, setSponsorBalance] = useState(0)
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [openWarning, setOpenWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState(null)
    const [openInfo, setOpenInfo] = useState(false)
    const [infoMessage, setInfoMessage] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState('sponsor')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    const history = useHistory()

    const fetchUser = () => {
        console.log('fetchUser')

        setIsLoading(true)

        axios.get('/users/my-profile')
            .then(({ data }) => {
                setDeveloperBalance(data.profile.developerBalance)
                setSponsorBalance(data.profile.sponsorBalance)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    const fetchTransactions = (accountSelected) => {
        console.log('fetchTransactions of ' + accountSelected)

        setIsLoading(true)

        setSelectedAccount(accountSelected)

        axios.get(`/transactions?account=${accountSelected}`)
            .then(({ data: { rows } }) => {
                setTransactions(rows)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    const withdraw = () => {
        if (selectedAccount !== 'developer'){
            setOpenWarning(true)
            setWarningMessage('Only the developer account can be withdrawn.')
            return
        }

        const continueApp = window.confirm('Do you really want to withdraw current balance in developer account?')

        if (!continueApp) return

        setIsLoadingButton(true)

        const transaction = {
            account: selectedAccount,
            amount: developerBalance
        }
        axios.post('/transactions/withdraw', transaction)
        .then(( {data: { currentBalance }}) => {
            setDeveloperBalance(currentBalance)
            fetchTransactions(selectedAccount)
            setOpenInfo(true)
            setInfoMessage('Your withdrawal request is being processed. We\'ll send you a confirmation email when the operation is completed.')
            setIsLoadingButton(false)
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoadingButton(false)
        })
    }

    useEffect(() => {
        fetchUser()
        fetchTransactions(selectedAccount)
    }, [])

    return (
        <>  
            <h3>Transactions</h3>

            
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="Account"
                        value={selectedAccount}
                        onChange={(event) => fetchTransactions(event.target.value)}
                        name="radio-buttons-group"
                        row={true}
                    >
                        <FormControlLabel value="developer" control={<Radio />} label="Developer account" />
                        <FormControlLabel value="sponsor" control={<Radio />} label="Sponsor account" />
                    </RadioGroup>
                </FormControl>
                
                {!isLoading && (
                    <div>
                        <TableContainer component={Paper}>
                            <Table style={{ width: 600 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell style={{fontWeight: 'bold'}} align="center">Account</TableCell> */}
                                        <TableCell style={{fontWeight: 'bold'}} align="center">Type</TableCell>
                                        <TableCell style={{fontWeight: 'bold'}} align="center">Amount</TableCell>
                                        <TableCell style={{fontWeight: 'bold'}} align="center">Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions.map((row) => (
                                        <TableRow key={row._id}>
                                            {/* <TableCell component="th" scope="row">
                                                {row.account}
                                            </TableCell> */}
                                            <TableCell align="left">
                                                {row.type}
                                            </TableCell>
                                            <TableCell align="right">
                                                <span style={{color: (row.type === 'subscription-contribution' || row.type === 'withdrawal') ? 'red' : 'green'}}>
                                                    {row.type === 'subscription-contribution' || row.type === 'withdrawal' ? '-' + row.amount.toFixed(2) : '+' + row.amount.toFixed(2)}
                                                </span>
                                            </TableCell>
                                            <TableCell align="center">
                                                {new Date(row.creationDate).toISOString().split('T')[0]} {new Date(row.creationDate).toLocaleString('en-US', { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour12: false })}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    
                        <FormControl component="fieldset">
                            {selectedAccount === 'developer' && (
                                <div style={{marginTop: 20, display: '-webkit-inline-box'}}>
                                    <FormLabel style={{fontWeight: 'bold'}}>Current developer balance: USD {developerBalance.toFixed(2)}</FormLabel>
                                    {developerBalance > 0 && (
                                        <Button startIcon={isLoadingButton ? <CircularProgress size={26} color="secondary" /> : <Paid />} style={{marginLeft: 10}} size="small" onClick={() => withdraw()} >
                                            Withdraw
                                        </Button>
                                    )}
                                </div>
                            )}
                            {selectedAccount === 'sponsor' && (
                                <div style={{marginTop: 20, display: 'flex'}}>
                                    <FormLabel style={{fontWeight: 'bold'}}>Current sponsor balance: USD {sponsorBalance.toFixed(2)}</FormLabel>
                                </div>
                            )}
                        </FormControl>
                    </div>
                )}
                {isLoading && 
                    <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                        <div>
                            <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                            <InputLabel>Loading...</InputLabel>
                        </div>
                    </Box>
                }
            </div>
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openWarning}
                autoHideDuration={6000}
                onClose={() => setOpenWarning(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="warning" sx={{ width: '100%' }} >
                    {warningMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openInfo}
                autoHideDuration={6000}
                onClose={() => setOpenInfo(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="info" sx={{ width: 350 }} >
                    {infoMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Transactions