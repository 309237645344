import AccountCircle from '@mui/icons-material/AccountCircle'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { Alert, Box, Button, Chip, CircularProgress, Dialog, InputLabel, Snackbar, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import axios from 'axios'
import MuiLink from '@mui/material/Link'
import React, { useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom"
import ApplicationList from '../application/application-list'
import ContributionList from '../contribution/contribution-list'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 2 }}>
                <h3>{children}</h3>
            </Box>
        )}
        </div>
    );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const DevRequest = () => {

    let { path, url } = useRouteMatch();

    const [tab, setTab] = useState(0)

    let history = useHistory()
    let query = useQuery()

    const paymentStatus = query.get('payment')

    let { devRequestId } = useParams()

    const [devRequest, setDevRequest] = useState()
    const [contributions, setContributions] = useState()
    const [applications, setApplications] = useState()
    const [upVotesCount, setUpVotesCount] = useState()
    const [upVote, setUpVote] = useState()
    const [comment, setComment] = useState()

    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const startDate = useMemo(() => devRequest && devRequest.timeline.startDate ? new Date(devRequest.timeline.startDate) : null, [devRequest])

    const fetchDevRequest = () => {        
        setIsLoading(true)

        axios.get('/dev-requests/' + devRequestId + (paymentStatus == 'succeeded' ? '?forceReloadContribution=true' : ''))
            .then(({ data: { devRequest, contributions, applications, upVoted } }) => {
                setDevRequest(devRequest)
                setUpVotesCount(devRequest.upVotesCount ?? 0)
                setContributions(contributions)
                setApplications(applications)
                setUpVote(upVoted)
                setTimeout(() => {
                    setIsLoading(false)
                }, 0)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchDevRequest()
    }, [])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleUpVote = () => {
        setIsLoading(true)

        axios.post('/dev-requests/' + devRequestId + '/up-vote')
            .then(({ data: devRequest }) => {
                setUpVotesCount(devRequest.upVotesCount)
                setUpVote(!upVote)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    } 

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    return devRequest ? (
        <div>
            {!isLoading && (
                <div>
                    <div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Typography variant="h6">
                                    {devRequest.type + ' / ' + devRequest.title}<span style={{marginLeft: 20, backgroundColor: 'red', color: 'white', borderRadius: 10, padding: 5, paddingLeft: 10, paddingRight: 10 }}>{devRequest.status}</span>
                                </Typography>
                                <Typography style={{color: 'gray'}} variant="subtitle1"><MuiLink target="_blank" href={`https://${devRequest.project}`}>{devRequest.project}</MuiLink></Typography>
                            </div>
                            <div  style={{display: 'flex', flexDirection: 'row'}}>
                                <Button variant="outlined" onClick={() => history.push(`${url}/contribute`)}>Contribute</Button>
                                <Button component={Link} variant="outlined" style={{marginLeft: 10}} to={`${url}/apply`}>Apply</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{flexGrow: 1}}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Discussion" {...a11yProps(0)} />
                                    <Tab label="Applications" {...a11yProps(1)} />
                                    {false && (
                                    <Tab label="Contributions" {...a11yProps(2)} />
                                    )}
                                </Tabs>
                            </Box>
                            <TabPanel value={tab} index={0}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{minHeight: 150, display: 'flex', flexDirection: 'column', alignContent: 'center', marginRight: 10}}>
                                            <AccountCircle fontSize="large" style={{alignSelf: 'end'}} />
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', flexGrow: 1}}>
                                                <IconButton
                                                    color={(upVote ? 'secondary' : 'default')}
                                                    aria-label="arrow"
                                                    onClick={handleUpVote}
                                                    >
                                                    <ThumbUpIcon />
                                                </IconButton>
                                                <Typography style={{textAlign: 'center', backgroundColor: '#00000010'}} variant="h5" color='default'>{upVotesCount}</Typography>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor: '#f0f0f0', padding: 10, borderRadius: 5, flexGrow: 1 }}>
                                            <Typography variant="body1" component={ReactMarkdown}>{devRequest.description}</Typography>
                                        </div>
                                    </div>
                                    <TextField style={{marginTop: 20}} fullWidth multiline variant="filled" rows={4} label='Contribute with the scope definition of this Dev Request ...' value={comment} onChange={(e) => setComment(e.target.value)} />
                                    <Tooltip title="Comming soon!">
                                        <span style={{ alignSelf: 'end' }}>
                                            <Button disabled style={{ pointerEvents: 'none' }}>
                                                Comment
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <ApplicationList applications={applications} refetch={fetchDevRequest} type='byDevRequest' />
                            </TabPanel>
                            { false && (
                            <TabPanel value={tab} index={2}>
                                <ContributionList type='byDevRequest' contributions={contributions}/>
                            </TabPanel>
                            )}
                        </div>
                        <div style={{minWidth: 200, marginTop: 49, padding: 20, borderLeftStyle: 'solid', borderLeftWidth: 1, borderLeftColor: '#0000001F'}}>
                            <Typography variant="caption" style={{fontWeight: 'bold'}}>Related Issues</Typography>
                            <div>
                                {devRequest.relatedIssues.map(ri => (
                                    <Chip label={`#${ri}`} onClick={() => window.open(`https://${devRequest.project}/issues/${ri}`, "_blank")}></Chip>
                                ))}
                            </div>
                            <Typography variant="caption" style={{fontWeight: 'bold'}}>Contribution Amount</Typography>
                            <Typography>{'USD ' + (devRequest.contributionAmount ? devRequest.contributionAmount.toFixed(2) : '0.00')}</Typography>
                            {devRequest.assignedApplication && (
                                <>
                                    <Typography variant="caption" style={{fontWeight: 'bold'}}>Developer Assigned</Typography>
                                    <Typography>{devRequest.assignedApplication?.user.name}</Typography>
                                </>
                            )}
                            {startDate && (
                                <>
                                    <Typography variant="caption" style={{fontWeight: 'bold'}}>Start Date</Typography>
                                    <Typography>{startDate.toDateString()}</Typography>
                                </>
                            )}
                            {devRequest.prNumber && (
                                <>
                                    <Typography variant="caption" style={{fontWeight: 'bold'}}>Submitted PR</Typography>
                                    <div> 
                                        <Chip label={`#${devRequest.prNumber}`} onClick={() => window.open(`https://${devRequest.project}/pull/${devRequest.prNumber}`, "_blank")}></Chip>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isLoading && 
                <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                    <div>
                        <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                        <InputLabel>Loading...</InputLabel>
                    </div>
                </Box>
            } 
            <Dialog open={paymentStatus == 'succeeded'} aria-labelledby="form-dialog-title">
                <div style={{padding: 20}}>
                    <h3>Contribution payment completed succesfully!</h3>
                    <p>Now invite the community to sponsor this Request and also call expert developers to bid their valuable services!</p>
                    <Button onClick={() => history.push('/dev-requests/' + devRequest._id)}>OK</Button>
                </div>
            </Dialog>  
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
        </div>
    ) : null
}

export default DevRequest;