import React from 'react'
import { Link } from "react-router-dom"
import MuiLink from '@mui/material/Link'
import { Typography } from '@mui/material'

const ContributionList = ({ contributions, type = 'byUser' }) => {
  return (
    <>
      {type == 'byUser' && 
        contributions.map(contribution => {
          return (          
            <div key={contribution._id._id} style={{ borderBottomStyle: 'solid', borderBottomWidth: 1, borderColor: 'lightgray', padding: 10 }}>
              <MuiLink variant="h6" component={Link} to={'/dev-requests/' + contribution._id._id }>{type == 'byUser' ? contribution._id.type + '/' + contribution._id.title : contribution.user}</MuiLink>
              <Typography variant="caption" style={{ backgroundColor: 'red', color: 'white', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 4, marginLeft: 5 }}>{contribution._id.status}</Typography>
              <Typography variant="body2"><MuiLink target="_blank" href={`https://${contribution._id.project}`}>{contribution._id.project}</MuiLink></Typography>

              <div>
                <Typography variant='caption' style={{ marginRight: 20 }}><span style={{ fontWeight: 'bold' }}>Contributed Amount:</span> USD {contribution.totalAmount.toFixed(2)}</Typography>
                {contribution._id.timeline.startDate && <Typography variant="caption" style={{ marginRight: 20 }}><span style={{ fontWeight: 'bold' }}>Start Date:</span> {new Date(contribution._id.timeline.startDate).toDateString()}</Typography>}
              </div>
            </div>
          )
        })
      }
      {type == 'byDevRequest' && 
        contributions.map(contribution => {
          return (          
            <div key={contribution._id} style={{ borderBottomStyle: 'solid', borderBottomWidth: 1, borderColor: 'lightgray', padding: 10 }}>
              <MuiLink variant="h6" component={Link} to={'/dev-requests/' + contribution.devRequest._id }>{type == 'byUser' ? contribution.devRequest.type + '/' + contribution.devRequest.title : contribution.user.name}</MuiLink>
              <div>
                <Typography variant='caption' style={{ marginRight: 20 }}><span style={{ fontWeight: 'bold' }}>Contributed Amount:</span> USD {contribution.amount.toFixed(2)}</Typography>
              </div>
            </div>
          )
        })
      }
    </>
  )
}

export default ContributionList;