import { initializeApp } from "firebase/app"
import { getAuth, GithubAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, sendEmailVerification, updateProfile, signInWithEmailAndPassword } from "firebase/auth";
import axios from 'axios'

const app = initializeApp({
    apiKey: "AIzaSyD-IjspVj4KYViz6jDcp8mQ6w4TP0quwjc",
    authDomain: "opensourcework-ecd6c.firebaseapp.com",
    projectId: "opensourcework-ecd6c",
    storageBucket: "opensourcework-ecd6c.appspot.com",
    messagingSenderId: "25555296288",
    appId: "1:25555296288:web:a537c956aa2d80d121dc45",
    measurementId: "G-M6XZES6J7Y"
  }
)
const auth = getAuth(app);
const githubAuthProvider = new GithubAuthProvider();
const googleAuthProvider = new GoogleAuthProvider();
const signOut = async () => {
  axios.defaults.headers.common['Authorization'] = null
  auth.signOut();
};

const signUp = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password)
  return userCredential
}

export {
  auth,
  signOut,
  signUp,
  githubAuthProvider,
  googleAuthProvider,
  sendEmailVerification,
  updateProfile,
  signInWithEmailAndPassword
}