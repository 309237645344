'use strict'

import App from './app'
import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { BrowserRouter } from "react-router-dom"

const renderApp = (NextApp) => {
  render(
    <AppContainer>
      <BrowserRouter>
        <NextApp />
      </BrowserRouter>
    </AppContainer>,
    document.querySelector('[data-js="app"]')
  )
}

renderApp(App)

if (module.hot) {
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default
    renderApp(NextApp)
  })
}
