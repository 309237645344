import HelpIcon from '@mui/icons-material/HelpOutline'
import { Alert, Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, Radio, RadioGroup, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import CurrencyFormatCustom from '../utils/CurrencyFormatCustom'
import { useSnackbar } from 'notistack'

const Apply = () => {
    let history = useHistory()

    const { enqueueSnackbar } = useSnackbar()

    let { devRequestId } = useParams()

    const [devRequest, setDevRequest] = useState()
    const [bidValue, setBidValue] = useState()
    const [selectedApplicationMode, setSelectedApplicationMode] = useState()
    
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openWarning, setOpenWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchDevRequest = () => {
        setIsLoading(true)

        axios.get('/dev-requests/' + devRequestId)
            .then(({ data: { devRequest } }) => {
                setDevRequest(devRequest)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchDevRequest()
    }, [])

    const submit = (e) => {
        e.preventDefault()
        if (!selectedApplicationMode){
            setOpenWarning(true)
            setWarningMessage('Select the mode you want to apply for this request.')
            return
        }

        const continueApp = selectedApplicationMode === 'available_option' ? window.confirm('By confirming your availability, you are running to be assigned for this dev request. Keep in mind that if you\'re selected you will have two weeks to deliver the request.') : true

        if (!continueApp) return

        setIsLoading(true)

        const application = {
            availableToStart: selectedApplicationMode === 'available_option',
            bidValue: Number(bidValue),
            devRequest: devRequestId
        }
        axios.post(`/applications`, application)
        .then(() => {
            enqueueSnackbar('Application created successfully.', { variant: 'success' })
            setIsLoading(false)
            history.push('/applications')
        })
        .catch(error => {
            if (error?.response?.status === 401) {
                setOpenErrorUnauthorized(true)
                if(error?.response?.data?.error) {
                    setErrorUnauthorizedMessage(error?.response?.data?.error)
                }else {
                    setErrorUnauthorizedMessage('Authentication is required to make this request')
                }
            }else if(error?.response?.data?.error) {
                setOpenError(true)
                setErrorMessage(error?.response?.data?.error)
            }else if(error) {
                setOpenError(true)
                setErrorMessage('' + error)
            }
            setIsLoading(false)
        })
    }

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    return (
        <>
            {!isLoading && (
                <div>
                    <DialogTitle id="form-dialog-title">Apply for '{devRequest?.type} / {devRequest?.title}'</DialogTitle>
                    <form onSubmit={submit}>
                        <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                        <FormControl component="fieldset">
                                <FormLabel component="legend">Select how you want to apply for this request:</FormLabel>
                                <RadioGroup
                                    aria-label="contribution"
                                    value={selectedApplicationMode}
                                    onChange={(event) => setSelectedApplicationMode(event.target.value)}
                                    name="radio-buttons-group"
                                >
                                    <div style={{display:'flex'}}>
                                        <FormControlLabel value={'available_option'} control={<Radio />} label={'I\'m available to start immediately for the current contribution amount.'} />
                                        <Tooltip title="By selecting this option, we will notify others applicants and ask them if they are also interested in the current grant. After two days Open Coders will select the best available candidate.">
                                            <IconButton>
                                                <HelpIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <FormControlLabel value={'wait_option'} control={<Radio />} label={'Notify me when contribution reaches an amount.'} />
                                        <Tooltip title="By selecting this option, we will notify you when the application reaches the amount you selected or when someone else declare it's available for immediate start, so you can join if you are also available.">
                                            <IconButton>
                                                <HelpIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                            {selectedApplicationMode === 'wait_option' && (
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'end'}}>
                                    <Typography style={{ marginBottom: 8, marginRight: 10, fontSize: 16 }}>Notify me when contribution amount reaches:</Typography>
                                    <TextField
                                        label='Value in USD'
                                        InputProps={{
                                            inputComponent: CurrencyFormatCustom,
                                        }}
                                        value={bidValue}
                                        onChange={(e) => setBidValue(e.target.value)}
                                    />
                                </div>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => history.goBack()} variant="outlined">
                                Cancel
                            </Button>
                            <Button type="submit">
                                Apply
                            </Button>
                        </DialogActions>
                    </form>
                </div>       
            )}     
            {isLoading && 
                <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "10%"}}>                        
                    <div>
                        <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                        <InputLabel>Loading...</InputLabel>
                    </div>
                </Box>
            } 
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openWarning}
                autoHideDuration={6000}
                onClose={() => setOpenWarning(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="warning" sx={{ width: 350 }} >
                    {warningMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Apply