import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Alert, Autocomplete, Box, Button, CircularProgress, IconButton, InputLabel, Snackbar, TextField, Typography } from '@mui/material'
import MuiLink from '@mui/material/Link'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from "react-router-dom"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const DevRequests = () => {

    let history = useHistory()

    let query = useQuery()

    const [devRequests, setDevRequests] = useState([])
    const [page, setPage] = useState(0)
    const [inputvalue, setInputvalue] = useState('')
    const [options, setOptions] = useState([])
    const [projects, setProjects] = useState([])
    const [types, setTypes] = useState([])
    const [statuses, setStatuses] = useState([])
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [openErrorUnauthorized, setOpenErrorUnauthorized] = useState(false)
    const [errorUnauthorizedMessage, setErrorUnauthorizedMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchDevRequests = () => {
        setIsLoading(true)

        axios.post(`/dev-requests/search?page=${page}`, {
            projects,
            types,
            statuses
        })
            .then(({ data: { rows, page, rowsCount } }) => {
                setDevRequests(rows)
                setIsLoading(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    setOpenErrorUnauthorized(true)
                    if(error?.response?.data?.error) {
                        setErrorUnauthorizedMessage(error?.response?.data?.error)
                    }else {
                        setErrorUnauthorizedMessage('Authentication is required to make this request')
                    }
                }else if(error?.response?.data?.error) {
                    setOpenError(true)
                    setErrorMessage(error?.response?.data?.error)
                }else if(error) {
                    setOpenError(true)
                    setErrorMessage('' + error)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchDevRequests()
    }, [page, projects, types, statuses])

    useEffect(async () => {
        const { data } = await axios.get(`/projects?name=${inputvalue}`)
        setOptions(data)
    }, [inputvalue]) 

    const onCloseSnackbarErrorUnauthorized = () => {
        setOpenErrorUnauthorized(false)
        history.push('/sign-in')
    }

    return (
        <div>            
            <div style={{display: 'flex', flexDirection: 'row' }}>
                <div style={{padding: 5, width: 260}}>
                    <Button variant="contained" fullWidth component={Link} to="dev-requests/create">New Dev Request</Button>
                    <Autocomplete
                        multiple
                        style={{width: 250}}
                        filterOptions={(x) => x}
                        getOptionLabel={(option) => option.name}
                        disablePortal
                        onInputChange={(e, newValue) => setInputvalue(newValue)}
                        options={options}
                        renderInput={(params) => <TextField {...params} label="Project" />}
                        value={projects}
                        onChange={(e, newValue) => {
                            setProjects(newValue)
                        }}
                    />
                    <Autocomplete
                        multiple
                        style={{width: 250}}
                        disablePortal
                        options={['Feature', 'Fix', 'Chore']}
                        renderInput={(params) => <TextField {...params} label="Type" />}
                        value={types}
                        onChange={(e, newValue) => {
                            setTypes(newValue)
                        }}
                    />
                    <Autocomplete
                        multiple
                        style={{width: 250}}
                        disablePortal
                        options={['Github', 'Gitlab', 'Launchpad']}
                        getOptionDisabled={(option) =>
                            option !== 'Github'
                        }
                        renderInput={(params) => <TextField {...params} label="Server" />}
                    />
                    <Autocomplete
                        multiple
                        getOptionLabel={(option) => option.label}
                        style={{width: 250}}
                        disablePortal
                        options={[
                            { label: 'Open', value: 'open'},
                            { label: 'Pre-start', value: 'pre-start'},
                            { label: 'In Progress', value: 'in-progress'},
                            { label: 'PR Under Review', value: 'pr-under-review'},
                            { label: 'PR Pending Fixes', value: 'pr-pending-fixes'},
                            { label: 'PR Merged', value: 'pr-merged'},
                            { label: 'Archived', value: 'archived'},
                            { label: 'Resolved Externally', value: 'resolved-externally'}
                        ]}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                        }
                        renderInput={(params) => <TextField {...params} label="Status" />}
                        value={statuses}
                        onChange={(e, newValue) => {
                            setStatuses(newValue)
                        }}
                    />
                </div>
                {!isLoading && (
                    <div style={{flexGrow: 1, marginLeft: 5}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end'}}>
                            <IconButton 
                                aria-label="delete" 
                                disabled={page == 0}
                                onClick={() => setPage(page-1)}
                                >
                                <ArrowLeft />
                            </IconButton>
                            <span>{page+1}</span>
                            <IconButton 
                                aria-label="delete"
                                onClick={() => setPage(page+1)}
                                >
                                <ArrowRight />
                            </IconButton>
                        </div>
                        {devRequests.map(devReq => (
                            <div key={devReq._id} style={{ borderBottomStyle: 'solid', borderBottomWidth: 1, borderColor: 'lightgray', padding: 10}}>
                                <MuiLink variant="h6" component={Link} to={`dev-requests/${devReq._id}`}>{devReq.type} / {devReq.title}</MuiLink>
                                <Typography variant="caption" style={{backgroundColor: 'red', color: 'white', padding: 4, paddingLeft: 8, paddingRight: 8, borderRadius: 4, marginLeft: 5}}>{devReq.status}</Typography>
                                <Typography variant="body2"><MuiLink target="_blank" href={`https://${devReq.project}`}>{devReq.project}</MuiLink></Typography>
                                <Typography style={{marginTop: 10}} variant="body1">{devReq.description && devReq.description.length > 200 ? devReq.description.substring(0, 300) + ' ...' : devReq.description}</Typography>
                            </div>
                        ))}
                    </div>
                )}
                {isLoading && (
                    <div style={{flexGrow: 1}}>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{marginTop: "20%"}}>                        
                            <div>
                                <CircularProgress style={{marginLeft: 10}} size={50} color="secondary" /> 
                                <InputLabel>Loading...</InputLabel>
                            </div>
                        </Box>
                    </div>
                )}  
            </div>            
            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={() => setOpenError(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: '100%' }} >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorUnauthorized}
                autoHideDuration={6000}
                onClose={() => onCloseSnackbarErrorUnauthorized(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="error" sx={{ width: 350 }} >
                    {errorUnauthorizedMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default DevRequests;