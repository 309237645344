import * as React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="USD "
      />
    );
  });

  export default CurrencyFormatCustom