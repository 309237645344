import React from "react"
import { TextField, Select } from "@mui/material"

const Profile = () => {
    const [firstName, setFirstName] = React.useState()
    const [lastName, setLastName] = React.useState()
    const [email, setEmail] = React.useState()
    const [role, setRole] = React.useState()


    return (
        <>  
            <h3>Profile</h3>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <TextField
                    required
                    label='First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    required
                    label='Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                    required
                    label='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Select
                    required
                    label='Role'
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                />
            </div>
        </>
    )
}

export default Profile